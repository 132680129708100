<template>
  
    <div class="pb journal_page">

    <div class="journal journal_list">


    <UiBread
        :list="[
          {name: $t('journal_view_link_text'), link: '/journal'},
          {name: tag},
        ]"
        position="left"
        /> 


      <div class="posts">
        <div class="latests small_boxes" v-if="journal_list_data?.items_blog?.length">

            <div class="small_boxes">
              <div class="ptl">{{ tag }} </div>
              <JournalBox 
                v-for="(item,ind) in journal_list_data.items_blog" 
                :class="'jbox box_list num_' + (ind+1)" 
                :key="ind"
                :data="item"      
                />
            </div>

          <client-only>
            <InfinityScroll 
              :is-loading="is_loading"
              ref="el_infinity_scroll"/>
          </client-only>

        </div>
      </div>

      <div class="sdbr" v-if="journal_list_data"> 

      <JournalVoteItems v-if="journal_list_data.items_vote?.votes?.length"
        :data="journal_list_data.items_vote.votes"      
        />

      <JournalVacancy v-if="journal_list_data.blog_vacancy?.length"
        :data="journal_list_data.blog_vacancy"      
        />

      <JournalTagItems v-if="journal_list_data.items_tag?.length"
        :data="journal_list_data.items_tag"      
        />

      </div>

    </div>
  </div>

</template>

<script setup> 

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'
import InfinityScroll from '~/components/InfinityScroll.vue';

const start = ref(0)
const limit = ref(20)
const is_ended = ref(false)
const {$api, $tagsUtil, $ga} = useNuxtApp()
const route = useRoute();
const el_infinity_scroll = ref(null)
const tag = route.params['var']
const blogs_count = ref(0)

const loadData = async function() {     
  const response = await $api.getJournalTagList(
    start.value,
    limit.value,
    route.params['var']
  )

  return response
}


const { pending: is_loading,  data: journal_list_data } = await useLazyAsyncData('journal_list_data', async () => await loadData())


useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)


watchArray([start], async ([new_start], added, removed) => {
  console.log('watcher');

  if(new_start == 0){
    journal_list_data.value = {items_blog:[],items_vote:[],blog_vacancy:[],items_tag:[]};
    is_ended.value = false;
  }

  is_loading.value = true;

  var dt = await loadData();

    
  if(dt.length == 0)
    is_ended.value = true;
  
  if(dt.items_blog.length){
    journal_list_data.value.items_blog = [...journal_list_data.value.items_blog, ...dt.items_blog]  
  }

  is_loading.value = false;
}, {deep: true})


useHead(useNuxtApp().$head.getJournalsTag(route.params['var'],blogs_count.value));

watchArray([route], async ([new_route], added, removed) => {
  
  useHead(useNuxtApp().$head.getJournalsTag(route.params['var'],blogs_count.value));

}, {deep: true})


onBeforeUnmount(() => {
  journal_list_data.value = null;
})

</script>

<style scoped>

@import '@/assets/css/journal-list.css';
/*
.journal_page {
    padding: 0 2rem;
    margin: 2rem 0;
}
 

@media (max-width: 768px) {
 
  .journal_page{
    padding: 0 2rem;
    margin: 2rem 0;
  }

}

.journal_list{
    
    display: grid;
    grid-template-columns: calc(100% - 300px - 3rem) 300px;
    grid-template-rows: auto;
    grid-gap: 3rem;
    grid-template-areas: 
      "posts sdbr";
  }
  
  
@media (min-width: 768px){
.posts {
    grid-area: posts;
}  
}
*/
</style>


<style>


</style>